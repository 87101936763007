<template>

  <div class="w-full flex flex-wrap">
    <h3 style="{fontWeight: '900'}" v-if="user.is_q_super_admin">{{$vs.rtl ? venue.arabic_name : venue.name}}</h3>
    <v-select
        v-else
        class="v-select-background text-xs lg:text-sm lg:w-64 w-32"
        :value="$vs.rtl ? venue.arabic_name : venue.name"
        @input="changeVenue"
        :options="agentVenues"
        :label="$vs.rtl ? 'arabic_name' : 'name'"
        :clearable="false"
        :dir="$vs.rtl ? 'rtl' : 'ltr'"
      />
      <div class="flex flex-col p-2 px-4 justify-center" v-if="venue.subdomain_url">
        <a :href="venue.subdomain_url" target="_blank" rel="noopener noreferrer" title="Opens in new tab">
          <i class="fas fa-external-link-alt fa-lg"></i>
        </a>
      </div>
  </div>

</template>

<script>
import vSelect from 'vue-select'

export default {
  computed: {
    venue(){
      return typeof(this.$store.getters.currentVenue) == 'string' ? JSON.parse(this.$store.getters.currentVenue): this.$store.getters.currentVenue;
    },
    agentVenues(){
      return typeof(this.$store.state.auth.agentVenues) == 'string' ? JSON.parse(this.$store.state.auth.agentVenues) : this.$store.state.auth.agentVenues;
    },
    user(){
      return typeof(this.$store.state.auth.user) == 'string' ? JSON.parse(this.$store.state.auth.user) : this.$store.state.auth.user;
    },
  },
  components: {
    vSelect,
  },
  methods: {
    changeVenue(venue) {

      this.$store.dispatch('auth/changeVenue', venue)
      .then((r) => {
        let path = window.location.hash.split('/');
        path[1] = venue.id
        let newPath = '';
        path.forEach(element => {
          newPath += '/'+element
        });
        location.replace(window.location.origin + newPath);
        window.location.reload();
      })
    }
  },
  watch: {
    agentVenues: function(newVal) {
      console.log(newVal);
    }
  }
}
</script>

<style>
@media only screen and (min-width: 576px) and (max-width: 700px){
  .vs__selected {
    font-size: 0.5rem !important;
  }
}
@media only screen and (max-width: 430px) {
  .vs__selected {
    font-size: 0.6rem !important;
  }
}
</style>
